.loader {
  position: relative;
  width: 300px;
  height: 100px;
  margin: 20px auto;
  border-radius: 20px;
  transform: scale(0.4);
  &.small {
    transform: scale(0.2);
    width: auto;
  }
  .ball {
    position: absolute;
    left: -15px;
    bottom: 0;
    width: 0;
    height: 100%;
    background: #4a9;
    opacity: 0;
    &.one {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
    }
    &.two {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
    }
    &.three {
      -webkit-animation-delay: 0.4s;
      animation-delay: 0.4s;
    }
    &.four {
      -webkit-animation-delay: 0.6s;
      animation-delay: 0.6s;
    }
    &.five {
      -webkit-animation-delay: 0.8s;
      animation-delay: 0.8s;
    }
    &.six {
      -webkit-animation-delay: 1s;
      animation-delay: 1s;
    }
    -webkit-animation: moveBall 2.8s infinite linear;
    animation: moveBall 2.8s infinite linear;
    .inner {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 30px;
      height: 30px;
      margin-left: -15px;
      background: #000000;
      border-radius: 100%;
    }
    &.center {
      left: 50%;
      top: 50%;
      background: none;
      -webkit-animation: centerBall 2.8s infinite cubic-bezier(0.55, 0.055, 0.675, 0.19);
      animation: centerBall 2.8s infinite cubic-bezier(0.55, 0.055, 0.675, 0.19);
      .inner {
        top: -15px;
      }
    }
    @keyframes centerBall {
      0%,
      25%,
      100% {
        opacity: 1;
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      75% {
        opacity: 0;
      }
    }
  }
  @keyframes moveBall {
    0% {
      left: -10%;
      opacity: 0;
      -webkit-animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
      animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    }
    10% {
      opacity: 0;
    }
    28.7% {
      left: 50%;
      transform: rotate(-180deg);
      opacity: 1;
      -webkit-animation-timing-function: linear;
      animation-timing-function: linear;
    }
    71.3% {
      left: 50%;
      transform: rotate(180deg);
      opacity: 1;
      -webkit-animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
      animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
    90% {
      opacity: 0;
    }
    100% {
      left: 110%;
      transform: rotate(deg);
      opacity: 0;
    }
  }
  &.white {
    .inner {
      background: white;
    }
  }
  &.red {
    .inner {
      background: #fd2b55;
    }
  }
}
