.react-tel-input {
    .form-control {
        width: 100%;
        color: #A6A6A6;
        border: 0;
        font-size: 20px;
    }
    .flag-dropdown {
        background: none;
        border: none;
    }
    .flag {
        transform: scale(1.5);
    }
}