.react-calendar {
  width: 80%;
  max-width: 100%;
  background: white;
  font-family: "SF Pro Display", "Helvetica Neue", Helvetica, Arial, sans-serif;;
  line-height: 1.125em;
}

.react-calendar-schedule {
  width: 100%;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 15px;
  margin-bottom: 10px;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #181818;
}

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #181818;
}

.react-calendar__tile.react-calendar__month-view__days__day--weekend:not(.react-calendar__tile--range):not(.disabled):not(.loading):not(.react-calendar__month-view__days__day--neighboringMonth) abbr {
  color: #181818;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 17px;
  display: flex;
  justify-content: center;
}

.react-calendar__tile--active {
  font-size: 15px;
  font-weight: 700;
}

.react-calendar__tile.hidden {
  display: none;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #dcdcde;
}

.react-calendar__tile abbr {
  min-width: 45px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar__tile.circle abbr {
  border-radius: 50px;
}

.react-calendar__tile.today abbr {
  background: transparent;
  border: 1px solid #dcdcde;
  color: #1D1D1F;
}

.react-calendar__tile.selected abbr {
  background: #181818;
  border: 1px solid #181818;
  color: white;
}

.react-calendar__tile.disabled abbr {
  color: #dcdcde;
  text-decoration: line-through;
  cursor: not-allowed;
}

.react-calendar__tile.loading abbr {
  color: #dcdcde;
}

.react-calendar__tile:not(.selected):not(.disabled):not(.react-calendar__tile--range) abbr:hover,
.react-calendar__tile:not(.selected):not(.disabled):not(.react-calendar__tile--range) abbr:focus {
  border-radius: 50px;
  background: #f5f5f6;
  border: 1px solid #f5f5f6;
}

.react-calendar--selectRange .react-calendar__tile--hover:not(.disabled):not(.react-calendar__tile--rangeStart) {
  background-color: #f5f5f6;
}

.react-calendar__tile--rangeStart:not(.single-selection):not(.loading):not(.react-calendar__tile--hasActive) {
  background: linear-gradient(#181818, #181818) !important;
  background-size: 50% 95% !important;
  background-position: right !important;
  background-repeat: no-repeat !important;
}

.react-calendar__tile--rangeEnd:not(.single-selection):not(.loading):not(.react-calendar__tile--rangeBothEnds) {
  background: linear-gradient(#181818, #181818);
  background-size: 50% 95%;
  background-position: left;
  background-repeat: no-repeat;
}

.react-calendar__tile--range:not(.react-calendar__tile--rangeStart):not(.react-calendar__tile--rangeEnd):not(.single-selection):not(.loading) {
  background: linear-gradient(#181818, #181818);
  background-size: 100% 95%;
  background-position: left;
  background-repeat: no-repeat;
  color: white;
}

@media only screen and (max-width: 460px) {
  .react-calendar__tile abbr {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }

  .react-calendar {
    width: 100%;
  }
}

