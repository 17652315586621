@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-Medium.eot');
  src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
    url('SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-Medium.woff2') format('woff2'),
    url('SFProDisplay-Medium.woff') format('woff'),
    url('SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-Heavy.eot');
  src: local('SF Pro Display Heavy'), local('SFProDisplay-Heavy'),
    url('SFProDisplay-Heavy.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-Heavy.woff2') format('woff2'),
    url('SFProDisplay-Heavy.woff') format('woff'),
    url('SFProDisplay-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-UltralightItalic.eot');
  src: local('SF Pro Display UltralightItalic'), local('SFProDisplay-UltralightItalic'),
    url('SFProDisplay-UltralightItalic.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-UltralightItalic.woff2') format('woff2'),
    url('SFProDisplay-UltralightItalic.woff') format('woff'),
    url('SFProDisplay-UltralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-RegularItalic.eot');
  src: local('SF Pro Display Regular Italic'), local('SFProDisplay-RegularItalic'),
    url('SFProDisplay-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-RegularItalic.woff2') format('woff2'),
    url('SFProDisplay-RegularItalic.woff') format('woff'),
    url('SFProDisplay-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-Thin.eot');
  src: local('SF Pro Display Thin'), local('SFProDisplay-Thin'),
    url('SFProDisplay-Thin.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-Thin.woff2') format('woff2'),
    url('SFProDisplay-Thin.woff') format('woff'),
    url('SFProDisplay-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-Light.eot');
  src: local('SF Pro Display Light'), local('SFProDisplay-Light'),
    url('SFProDisplay-Light.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-Light.woff2') format('woff2'),
    url('SFProDisplay-Light.woff') format('woff'),
    url('SFProDisplay-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-BlackItalic.eot');
  src: local('SF Pro Display Black Italic'), local('SFProDisplay-BlackItalic'),
    url('SFProDisplay-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-BlackItalic.woff2') format('woff2'),
    url('SFProDisplay-BlackItalic.woff') format('woff'),
    url('SFProDisplay-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-Bold.eot');
  src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
    url('SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-Bold.woff2') format('woff2'),
    url('SFProDisplay-Bold.woff') format('woff'),
    url('SFProDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-Black.eot');
  src: local('SF Pro Display Black'), local('SFProDisplay-Black'),
    url('SFProDisplay-Black.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-Black.woff2') format('woff2'),
    url('SFProDisplay-Black.woff') format('woff'),
    url('SFProDisplay-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-SemiboldItalic.eot');
  src: local('SF Pro Display SemiboldItalic'), local('SFProDisplay-SemiboldItalic'),
    url('SFProDisplay-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-SemiboldItalic.woff2') format('woff2'),
    url('SFProDisplay-SemiboldItalic.woff') format('woff'),
    url('SFProDisplay-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-Ultralight.eot');
  src: local('SF Pro Display Ultralight'), local('SFProDisplay-Ultralight'),
    url('SFProDisplay-Ultralight.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-Ultralight.woff2') format('woff2'),
    url('SFProDisplay-Ultralight.woff') format('woff'),
    url('SFProDisplay-Ultralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-LightItalic.eot');
  src: local('SF Pro Display LightItalic'), local('SFProDisplay-LightItalic'),
    url('SFProDisplay-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-LightItalic.woff2') format('woff2'),
    url('SFProDisplay-LightItalic.woff') format('woff'),
    url('SFProDisplay-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-ThinItalic.eot');
  src: local('SF Pro Display ThinItalic'), local('SFProDisplay-ThinItalic'),
    url('SFProDisplay-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-ThinItalic.woff2') format('woff2'),
    url('SFProDisplay-ThinItalic.woff') format('woff'),
    url('SFProDisplay-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-MediumItalic.eot');
  src: local('SF Pro Display MediumItalic'), local('SFProDisplay-MediumItalic'),
    url('SFProDisplay-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-MediumItalic.woff2') format('woff2'),
    url('SFProDisplay-MediumItalic.woff') format('woff'),
    url('SFProDisplay-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-Semibold.eot');
  src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
    url('SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-Semibold.woff2') format('woff2'),
    url('SFProDisplay-Semibold.woff') format('woff'),
    url('SFProDisplay-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-HeavyItalic.eot');
  src: local('SF Pro Display HeavyItalic'), local('SFProDisplay-HeavyItalic'),
    url('SFProDisplay-HeavyItalic.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-HeavyItalic.woff2') format('woff2'),
    url('SFProDisplay-HeavyItalic.woff') format('woff'),
    url('SFProDisplay-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-Regular.eot');
  src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
    url('SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-Regular.woff2') format('woff2'),
    url('SFProDisplay-Regular.woff') format('woff'),
    url('SFProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-BoldItalic.eot');
  src: local('SF Pro Display BoldItalic'), local('SFProDisplay-BoldItalic'),
    url('SFProDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-BoldItalic.woff2') format('woff2'),
    url('SFProDisplay-BoldItalic.woff') format('woff'),
    url('SFProDisplay-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('SFProDisplay-Regular.eot');
  src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
    url('SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
    url('SFProDisplay-Regular.woff2') format('woff2'),
    url('SFProDisplay-Regular.woff') format('woff'),
    url('SFProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: local('SF Pro Text Regular'), local('SFProText-Regular'),
    url('SFProText-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
