.hamburger {
    position: relative;
    width: 20px;
    height: 14px;
    margin-bottom: -4px;
    transform: scale(0.8);
}

.hamburger .bar {
    position: absolute;
    display: block;
    width: 20px;
    height: 3px;
    padding: 0;
    background-color: #181818;
    transition: all 0.4s ease-in-out;
}

.bar1 {
    top: 0;
}

.bar4 {
    bottom: 3px;
}

.checkbox {
    margin-left: 6px;
}

.checkbox.active {
    .hamburger {
        .bar {
            background-color: rgb(199, 199, 199);
        }
        .bar1 {
            animation: topBarAnimation 0.7s normal forwards ease-in-out;
        }
        .bar4 {
            animation: bottomBarAnimation 0.7s normal forwards ease-in-out;
        }
    }
}

@keyframes topBarAnimation {
    40% {
        top: 5px;
    }

    100% {
        top:4px;
        width: 25px;
        margin-left: -5px;
        transform: rotate(225deg);
    }
}

@keyframes bottomBarAnimation {
    40% {
        bottom: 5px;
    }

    100% {
        bottom: 7px;
        width: 25px;
        margin-left: -5px;
        transform: rotate(-225deg);
    }
}