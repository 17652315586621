body {
  color: #181818;
  background: #f6f6f6;
  font-family: 'SF Pro Display';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar-thumb {
  background: #dcdcde;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background: #f5f5f6;
  border-radius: 6px;
}

::-webkit-scrollbar {
  display: none;
  width: 10px;
  -webkit-appearance: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}